import React from 'react';
import logo from './solis-logo.png';
import styled from '@emotion/styled';

const Section = styled.section`
  height:100vh;
	width: 100vw;
	display:flex;
	justify-content:center;
	align-items:center;
  flex-direction:column;
  img {
    max-width:50vw;
    max-height:50vh;
  }
  p {
    color: #ffffff;
    margin-top:40px;
    text-align:center;
    line-height:1.5;
    font-family: brandon-grotesque, sans-serif;
    font-size: 24px;
    a {
      color: #ffffff;
      text-decoration: none;
      border-bottom: 1px solid #d4af66;
    }
  }
  h1 {
    margin-top:40px;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 600;
    font-size:35px;
    color: #ffffff;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 600px) {
    img {
    max-width:80vw;
    max-height:80vh;   
    }
  }
`;

function App() {
  return (
    <Section id="main">
      <img src={logo} alt="Solis logo" />
      <h1>Coming Soon.</h1>
      <p>For more information please contact <a href="mailto:info@soliswellness.com">info@soliswellness.com</a></p>
    </Section>
  );
}

export default App;
